import React from 'react';
import styles from './Button.scss';

const Button = ({ type, label, onClick }) => {
    return (
        <button className={type} onClick={onClick}>
            {label}
        </button>
    );
};

export default Button;