import React from 'react';
// import { Table, TableCellead, TableBody, Tr, TableCell, TableCell, Flex, Button, HStack, Link } from "@chakra-ui/react"
import {  GrEdit, GrCloudDownload } from "react-icons/gr";
import { Table,
    TableHead, 
    TableRow,
    Button,
    TableBody,
    TableCell,
    Flex } from "@aws-amplify/ui-react";
import EditSoftware from './EditSoftware'
import CampfireAssetService from '../../services/svc_assets';

const assetTemplate = {
    filename:'',
    versionstring:'',
    version: 1,
    file: { name: ''},
    platform: '',
    status: 'new'
}

export default function SoftwareList(props) {
    const [showModal, setShowModal] = React.useState();
    const [currentAsset, setcurrentAsset] = React.useState();

    function popModal(account) {
        setcurrentAsset(account); 
        setShowModal(true);
    }

    function downloadAsset( asset ) {
        props.downloadAsset(asset);
    }

    function onModalClosed(result) {
        // determine if it is an add or an edit
        if( result.status && result.status === 'new') {
            props.onCreateAsset(result);
        } else if( result.filename) {   // check any key to ensure result isn't {}
            props.onUpdateAsset(result);
        }
        setShowModal(false);
    }

    return (
        <>
            <Flex p="2">
                <Button variation="primary" onClick={(e) => {popModal({...assetTemplate})}  }>Add Shared Asset</Button>
            </Flex>

            <Table variant="simple">
                {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                <TableHead>
                    <TableRow>
                        {/* <TableCell>Id</TableCell> */}
                        <TableCell>Filename</TableCell>
                        <TableCell>Version</TableCell>
                        <TableCell>Platform</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                    props.sharedassets && props.sharedassets.map((asset, index) => (
                        <TableRow className="withBackdrop" key={index}>
                            {/* <TableCell>{asset.assetid}</TableCell> */}
                            <TableCell>{asset.filename}</TableCell>
                            <TableCell>{asset.versionstring}</TableCell>
                            <TableCell>{asset.platform}</TableCell>
                            <TableCell> 
                                <Flex direction='row'>
                                    <GrEdit w={4} h={4}  onClick={(e) => { popModal(asset); }}/>
                                    {asset.platform == 'Windows' && <GrCloudDownload w={4} h={4}  onClick={(e) => { downloadAsset(CampfireAssetService.keyNameFromAsset(asset)); }}/>  }
                                </Flex>
                            </TableCell>

                            <div className="rowBackdrop"></div>

                        </TableRow>
                    ))
                    }
                </TableBody>
            </Table>

            <EditSoftware
                asset={currentAsset}
                showModal={showModal} 
                onModalClosed={onModalClosed} />
        </>
    );
}