import React from 'react';
// import { Table, TableCellead, TableBody, Tr, TableCell, TableCell, Flex, Button, HStack, Link } from "@chakra-ui/react"
import { GrGroup, GrEdit } from "react-icons/gr";
import { Table,
    TableHead, 
    TableRow,
    Button,
    Card,
    TableBody,
TableCell,
Link, Flex } from "@aws-amplify/ui-react";
import EditAccount from './EditAccount'
import {  Link as ReachLink} from "@gatsbyjs/reach-router";

// import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons"
// import { useTable, useSortBy } from "react-table"

const accountTemplate = {
    name:'',
    cloudStorageAccess: 3,
    storageAccountIdentityPoolID: '',
    storageAccountRegion: '',
    storageAccountProjectDataBucketName: '',
    storageAccountProjectDataBucketIsAccelerated: false,
    storageAccountSoftwareBucketName: '',      
    status: 'new'
}

export default function AccountList(props) {
    const [showModal, setShowModal] = React.useState();
    const [currentAccount, setCurrentAccount] = React.useState();

    function popModal(account) {
        if( account.storageAccountProjectDataBucketIsAccelerated == null)
            account.storageAccountProjectDataBucketIsAccelerated = false;
        setCurrentAccount(account); 
        setShowModal(true);
    }

    function onModalClosed(result) {
        // determine if it is an add or an edit
        if( result.status && result.status === 'new') {
            props.onCreateAccount(result);
        } else if( result.name) {
            props.onUpdateAccount(result);
        }
        setShowModal(false);
    }


    return (
        <>
            <Flex p="2">
                <Button variation="primary" onClick={(e) => {popModal({...accountTemplate})}  }>Add Team</Button>
            </Flex>

            <Table variant="simple">
                {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                <TableHead>
                    <TableRow>
                        {/* <TableCell>Id</TableCell> */}
                        <TableCell>Name</TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                    props.accounts && props.accounts.map((account, index) => (
                        <TableRow className="withBackdrop" key={index}>
                            {/* <TableCell>{account.accountid}</TableCell> */}
                            <TableCell>{account.name}</TableCell>
                            <TableCell>{account.accountid}</TableCell>
                            <TableCell> 
                                <Flex direction='row'>
                                    <Link as={ReachLink} to={`/admin/account/${account.accountid}/person`}>
                                        <GrGroup w={4} h={4} /> 
                                    </Link>
                                    <GrEdit w={4} h={4}  onClick={(e) => { popModal(account); }}/>
                                </Flex>
                            </TableCell>

                            <div className="rowBackdrop"></div>

                        </TableRow>
                    ))
                    }
                </TableBody>
            </Table>


            
            <EditAccount
                account={currentAccount}
                showModal={showModal} 
                onModalClosed={onModalClosed} />
        </>
    );
}