/* src/App.js */
import React from 'react'
import AppLayout from './components/applayout/AppLayout'
import { useAuthenticator, Authenticator } from '@aws-amplify/ui-react';

import "./App.css";

const AuthStateApp = () => {
  const { user } = useAuthenticator();

  React.useEffect(() => {

  }, []);


  if( user ) {
    return  (
        <AppLayout user={user}></AppLayout>
    ) ;
  } else {
    return (
      <div>
        <div id='logo'></div>      
        <Authenticator className="campfire-app" hideSignUp={true} />
        <div id="swoosh"></div>
        <div id="swoosh2"></div>   
    </div>      
    );
  }
}

export default AuthStateApp;
