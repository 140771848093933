// AT:this component is written by ChatGPT

import React, { useState, useEffect } from 'react';
import { FaSun, FaMoon } from 'react-icons/fa';

const DarkModeToggle = () => {
    const [isDarkMode, setIsDarkMode] = useState(
        JSON.parse(localStorage.getItem('isDarkMode')) || false
    );

    useEffect(() => {
        document.body.classList.toggle('dark', isDarkMode);
    }, [isDarkMode]);

    const handleDarkModeToggle = () => {
        setIsDarkMode(!isDarkMode);
        localStorage.setItem('isDarkMode', JSON.stringify(!isDarkMode));
    };

    return (
        <button className="iconButton darkModeButton" onClick={handleDarkModeToggle}>
            {isDarkMode ? (
                <FaMoon />
            ) : (
                <FaSun />
            )}
        </button>
    );
};

export default DarkModeToggle;
