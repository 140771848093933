import { getAccount } from '../graphql/queries'
import { API } from 'aws-amplify'
import AppConfig from '../config';


const CampAccountSvc = {
    getAccount: async (accountId) => {
      let account = {};
      try {
        let actData = await API.graphql({ query: getAccount, variables: { accountid:  accountId } })
        account = actData.data.getAccount;

      } catch (err) { console.log('error fetching account', err) }
      return account;
    }   

};

export default CampAccountSvc;
