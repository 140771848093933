
const configData = {
    region: "us-east-2",
    userPoolId: "us-east-2_r2OHYjV16",
    appClientId: "5g29ta7rr662vcn54e813a161g",
    graphQlEndpoint: "https://ebhqmkh4lzcv5hpxpfhzjteafq.appsync-api.us-east-2.amazonaws.com/graphql",
    identityPoolId: "bb492355-a16a-46d4-a313-7435e4e2ba02",
    softwareBucket: "campfire-249469908103-downloads",
    accountBucket: "campfire-249469908103-customerassets"
};

export default configData;