import React, { useEffect } from"react";

import '../../Layout.scss';
import '../../Elements.scss';
import './SoftwarePage.scss';

import {ReactComponent as RemoteQR} from "../releaseNotes/qr-remote.svg";
import {ReactComponent as IpadQR} from "../releaseNotes/qr-ipad.svg";

import Button from "../Input/Button";

import VimeoPlayerPopup from "../videoPlayer/VimeoPlayerPopup"
import ReleaseNotesDialog from "../releaseNotes/ReleaseNotesDialog"


function SoftwarePage(props) {

    return (
      <div className="page-container software-page">

      <section id="hero">
          <h1>Compose and Collaborate <br/> with Campfire</h1>
          <div className="overlay"></div>
      </section>

      <section id="pcSoftware">
          <div className="leftVisual">
              <img src="assets/pcViewer.png"></img>
          </div>
          <div className="left">
              <h1>Campfire for PC</h1>
              <div className="row">
                  <span className="version">v. {props.version}</span>
                  <ReleaseNotesDialog></ReleaseNotesDialog>
              </div>
              <div className="textBlock">
                  <h3>For use with Campfire Headset</h3>
                  <ul>
                      <li>Core i7 or better</li>
                      <li>NVIDIA RTX 2060 RTX or better</li>
                      <li>Thunderbolt 3/4</li>
                      <li>Windows 10 2004, Windows 11</li>
                  </ul>
              </div>
              <div className="row download-row">
                  <Button type="primary" label="Download" version="" onClick={props.downloadCallback} />
              </div>
          </div>
          <div className="right">
              <div className="textBlock">
                  <h3>Remote App</h3>
                  <p>For headset users, the Remote app works with the Campfire Pack to turn your phone into an easy-to-use controller.</p>
              </div>
              <div className="row download-row">
                  <Button type="primary" 
                    label="Install from TestFlight" 
                    onClick={()=> window.open("https://testflight.apple.com/join/lA4A0Rif", "_blank")} />
                  <span className="sub"></span>
                  {/* https://www.qrcode-monkey.com/ */}
                  <RemoteQR className="svgFill"></RemoteQR>
              </div>
          </div>
          <div className="rightVisual">
              <div className="backdrop float-light"></div>
              <img className="headset" src="assets/headsetSide.png"></img>
              <img className="iphone" src="assets/iphoneUI.png"></img>
          </div>
      </section>

      <section id="iPadSoftware">
          <div className="container">
              <div className="backdrop float-light"></div>
              <div className="left">
                  <h1>Campfire for iPad</h1>
                  <div className="row">
                      <span className="version">v. {props.version}</span>
                      <ReleaseNotesDialog></ReleaseNotesDialog>
                  </div>
                  <h3>Minimum specs</h3>
                  <ul>
                      <li>iPad Pro (2020+) </li>
                      <li>iPad Air (2022+) </li>
                  </ul>
                  <div className="row download-row">
                      <Button 
                        type="primary" 
                        label="Install From TestFlight" 
                        onClick={()=> window.open("https://testflight.apple.com/join/pIu2mcub", "_blank")} />
                      <span className="sub"></span>
                      <IpadQR className="svgFill"></IpadQR>
                  </div>
              </div>
              <div className="right">
                  <img src="assets/iPad.png"></img>
              </div>
          </div>
      </section>

      <section id="getStarted">
          <h1>Get Started</h1>
          <div className="container">
              <div className="videoBlock compose float-light">
                  <div className="row headline">
                      <h3>Composing in Campfire</h3>
                      <span>(4 min)</span>
                  </div>
                  <VimeoPlayerPopup thumbnail="https://i.vimeocdn.com/video/1609970036-22e8766bf7dcc31e5d7f8ef7fb920cb127dbe3d7406ae0edf4fa24870b8454c6-d?mw=2100&mh=1181&q=70" videoId="797855367"></VimeoPlayerPopup>
                  {/*<p>*/}
                  {/*    Create scenes in Campfire. This can be some kind of description or the start of the transcript. I’m not really sure.*/}
                  {/*</p>*/}
              </div>
              <div className="videoBlock collaborate float-light">
                  <div className="row headline">
                      <h3>Collaborating in Campfire</h3>
                      <span>(2 min)</span>
                  </div>
                  <VimeoPlayerPopup thumbnail="https://i.vimeocdn.com/video/1609971690-097e360ee3bff37ccee5fce1b43bd2f61e635083e85bd51557d0b969550d0b39-d?mw=2100&mh=1181&q=70" videoId="797859190"></VimeoPlayerPopup>
                  {/*<p>*/}
                  {/*    Create scenes in Campfire. This can be some kind of description or the start of the transcript. I’m not really sure.*/}
                  {/*</p>*/}
              </div>
          </div>
      </section>

      {/*<section id="notionDocs">*/}
      {/*    <iframe src="https://v1.embednotion.com/embed/0f2f1e4207d64c2f8c05ea2cee23cfe8"></iframe>*/}
      {/*</section>*/}

      </div>
  );

}

export default SoftwarePage;