/* src/App.js */
import React from 'react'
import {Auth} from 'aws-amplify'
import { useAuthenticator, Authenticator, Link } from '@aws-amplify/ui-react';
import { navigate } from "@gatsbyjs/reach-router";
import {  Link as ReachLink} from "@gatsbyjs/reach-router";

import './Signup.css';
const defaultFormVals = {
  token: "",
  firstName: "",
  lastName: "",
  email: ""
}

const creds = {
  u: '',
  p: ''
};

const delayedSignIn = async () => {
  if( creds.u ) {
    await Auth.signIn(creds.u, creds.p)
    .then(() => {
      creds.u='';
      creds.p='';
    })
  }
  setTimeout(()=> {
    navigate("/home");
  },700);
  return false;
}

const services = {
  async handleSignUp(formData) {
    let { username, password, attributes } = formData;
    username = username.toLowerCase();
    attributes.email = attributes.email.toLowerCase();
    creds.u = username;
    creds.p = password;    
    return Auth.signUp({
      username,
      password,
      attributes,
    });
  },
};


const Signup = () => {
  
  const [defaultValues, setDefaultValues] = React.useState(defaultFormVals)
  const { user } = useAuthenticator();

  React.useEffect(() => {
    const qsParams = new URLSearchParams(window.location.search);

    if( qsParams.has('tk')) {

      let decoded = atob(decodeURIComponent(qsParams.get('tk')));
      const tkParams = new URLSearchParams(decoded);
      if( tkParams.has('t')) {
        // Force the authenticator into signup mode
        defaultFormVals.token = tkParams.get('t')
        
      }
  
      const formVals={...defaultFormVals};
      if( tkParams.has('f')) {
        formVals.firstName = tkParams.get('f')
      }
      
      if( tkParams.has('l')) {
        formVals.lastName = tkParams.get('l')
      }
      
      if( tkParams.has('e')) {
        formVals.email = tkParams.get('e');
      }  

      setDefaultValues(formVals);
    }

  }, []);

  const authFormFields = {
    signUp: {
      username: {
        readOnly: true,
        isRequired: true,
        value: defaultValues.email ,
        order: 1       
      },
      given_name: {
        readOnly: false,
        isRequired: true ,
        placeholder: 'First Name' ,
        order: 2  
      },
      family_name: {
        readOnly: false,
        isRequired: true  ,
        placeholder: 'Last Name' ,
        order: 3   
      }, 
      password: {
        placeholder: 'Create Password',
        order: 4
      },
      confirm_password: {
        placeholder: 'Confirm Create Password',
        order: 5
      },             
      email: {
        readOnly: true,
        isRequired: true,
        type: 'hidden',
        value: defaultValues.email  ,
        order: 6      
      },      
      profile: {
        readOnly: true,
        isRequired: true,
        value: defaultValues.token ,
        type: 'hidden' ,
        order: 7      
      },
    },
  }
 

  if( user ) {
    return  (
      <div className='swroot'>
        <div className="container">
          <div className="item">
            <div className="summary">
              <img alt="Software" className="icon" src='ViewerIcon.svg'></img>
              <div style={{ flexGrow: 1 }}></div>
            </div>
            <div className="description">
              <h2>Welcome to Campfire</h2>
              <p className="large">
                Thank you for signing up! 
              </p>
              <p className="large">Now that you have completed registration you may download and 
                install Campfire software by visiting the 
                <Link onClick={delayedSignIn} to="./" as={ReachLink}> Campfire Home Page</Link>
              </p>
            </div>
          </div>
         
        </div>
        <div id="swoosh"></div>
        <div id="swoosh2"></div>        
      </div>
    ) ;
  }

  return (
    <div>

      <div id='logo'></div>
      <Authenticator
        className='campfire-app' 
        services={services} 
        initialState='signUp' 
        formFields={authFormFields} >
      </Authenticator>
      <div id="swoosh"></div>
      <div id="swoosh2"></div>   
    </div>
  );
}

export default Signup;
