import React, { useState, useEffect } from "react";

const ReleaseNotesContent = (props) => {

    return (
        <>
            <h1>Release Notes</h1>

            <h3>New Features:</h3>
            <p>
            Combined Viewer and Scenes Applications
            <ul>
                <li>The Campfire Viewer application and the Campfire Scenes application have been combined into a single Campfire app, removing the need to navigate between the two.</li>
            </ul>
            </p>

            <p>
            Editing Tools on iPad
            <ul>
                <li>All of the editing tools that were previously only available in Desktop versions of Campfire are now available in the iPad version, allowing for more seamless editing across devices.</li>
            </ul>
            </p>

            <p>
            Improved Multiplayer Networking
            <ul>
                <li>Campfire is now using a new multiplayer communications and state service that provides more robust networking and faster connection times.</li>
            </ul>
            </p>


            <h3>Known Issues:</h3>

            <ul>
            <li>Remote and iPad applications display a crash message when force quitting.</li>

            <li>There is a long delay when applying a material to a model for the first time.</li>

            <li>USDZ format files fail to import on iPad.</li>

            <li>The flashlight tool doesn’t work with Unlit Shader.</li>

            <li>When the Presenter pauses the animation on a model, other users continue to see the animation.</li>

            <li>If the Presenter changes the name of the project it does not live update.</li>

            <li>The Grab tool has no undo feature.</li>
            <li>iPad cannot import files in .igs format or .mp4 format.</li>
            <li>Changing the unit size on one model in a scene will change it for all duplicates of the model.</li>
            <li>Sketch annotations are missing their connecting lines.</li>
            <li>Normal maps are turned off for PBR shader on iOS devices.</li>
            <li>If iPad is powered off while following another user, Campfire will need to be restarted</li>
            
            </ul>

            <p>
            We hope these updates and improvements will enhance your experience with Campfire. As always, please let us know if you encounter any issues or have any feedback. Thank you for using Campfire!

            </p>

            <br />
            <br />            
        </>
    );
};

export default ReleaseNotesContent;
