import React from 'react';
import './Header.css'

import {
    useAuthenticator,
    Button,
    View,
    Image,
    Link,
    Flex } from "@aws-amplify/ui-react";

// import DefaultDarkMode from '../darkMode/DefaultDarkMode';
import DarkModeToggle from "../darkMode/DarkModeToggle"

// Logout Icon
import { ImExit } from "react-icons/im";


export default function Header(props) {

    const { signOut } = useAuthenticator();
    const { user } = useAuthenticator();

    return (
    <View className="header">
        <div className="header-logo"></div>
        <span className="companyID">{props.companyName}</span>
        <Flex grow='1'></Flex>
        <Flex direction='row'>
            <DarkModeToggle className="darkModeButton"></DarkModeToggle>
            {/*<Button variation="link">Software</Button>*/}
            <Button onClick={signOut}><ImExit w={4} h={4}/></Button>
        </Flex>
        <div className="backplate"></div>
    </View>
    );
}