import React, { useState} from 'react';

import PersonList from './PersonList'
import  { API } from 'aws-amplify'
import { mutCreatePerson, 
    mutUpdatePerson, 
    mutSendInviteEmail,
    mutDeletePerson } from '../../graphql/mutations';
import { 
    getPersons, 
    getAccountPersons, 
    getAccount } from '../../graphql/queries';

import CampAuthService from '../../services/svc_auth';
import CampfireAssetService from '../../services/svc_assets';    

 
async function fetchAccount(accountid, setter) {
    if( accountid ) {
        let actData = await API.graphql({ query: getAccount, variables: { accountid: accountid } })
        setter(actData.data.getAccount.name)
    }
}

/**
 * fetch the persons from backend
 * 
 */
async function fetchPersons(accountid, setter) {
    try {
        let personData;
        if( accountid ) {
            personData = await API.graphql({ query: getAccountPersons, variables: { accountid: accountid } })
            setter(personData.data.getAccountPersons)
        }
        else {
            personData = await API.graphql({ query: getPersons })
            const accountMap = {};
            for(let act of personData.data.getAccounts) {
                accountMap[act.accountid] = act.name;
            }

            for (let person of personData.data.getPersons) {
                person.accountname = accountMap[person.accountid];
            }
            setter(personData.data.getPersons)
        }
    } catch (err) { console.log('error fetching persons', err) }
} 


export default function PersonListContainer(props) {
    const [persons, setPersons] = useState([])
    const [accountName, setAccountName] = useState('All')

    React.useEffect( () => {
        fetchAccount(props.accountid, setAccountName);
        fetchPersons(props.accountid, setPersons);
    }, [props.accountid]);
  
    async function deletePerson(person) {
        await API.graphql({ query: mutDeletePerson, 
            variables: { 
                personid: person.personid,
                accountid: person.accountid
            }
        });
        fetchPersons(person.accountid, setPersons);
    }

    /**
     * 
     * @param {*} person 
     */
    async function createPerson(person) {
        await API.graphql({ query: mutCreatePerson, 
            variables: { 
                person: {
                    email: person.email.toLowerCase(),
                    accountid: person.accountid,
                    firstName: person.firstName,
                    lastName: person.lastName,
                    username: person.username,
                    isAdmin: person.isAdmin,
                    appRole: person.appRole
                }
            }
        });
        fetchPersons(person.accountid, setPersons);
    }

    /**
     * 
     * @param {*} contact 
     */
    async function updatePerson(person) {
        await API.graphql({ query: mutUpdatePerson, 
            variables: {
                personid: person.personid, 
                person: {
                    accountid: person.accountid,
                    email: person.email,  // can't change this, its immutable after account creation
                    firstName: person.firstName,
                    lastName: person.lastName,
                    username: person.username,
                    isAdmin: person.isAdmin,
                    appRole: person.appRole
                }
            }
        });
        if( person.file ) {
            console.log("There is an image to save.");
            let s3Key = CampfireAssetService.keyNameFromPerson(person);
            let credentials = await CampAuthService.fetchCredentials(props.user);
            await CampfireAssetService.uploadUserImage(credentials, s3Key, person.file);            
        }
        fetchPersons(person.accountid,setPersons);
    } 
    
    async function sendInvitation(person) {
        await API.graphql({ query: mutSendInviteEmail, 
            variables: { 
                person: {
                    accountid: person.accountid,
                    email: person.email,
                    firstName: person.firstName,
                    lastName: person.lastName,
                    username: person.username,
                    isAdmin: person.isAdmin,
                    personid: person.personid
                }
            }
        });
    }    

    return (
        <PersonList
            accountname={accountName} 
            persons={persons}
            accountid={props.accountid} 
            onCreatePerson={createPerson}
            onSendInvite={sendInvitation}
            onDeletePerson={deletePerson} 
            onUpdatePerson={updatePerson}></PersonList>
    );
}