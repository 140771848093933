import React from 'react';
import { Formik } from 'formik';
import { 
  TextField,
  CheckboxField, 
  Card,
  RadioGroupField,
  Radio,
  Link,
  Button,
  Flex } from "@aws-amplify/ui-react";

import Modal from '../modal/Modal';

/**
 * 
 * @param {*} props { showModal: boolean, contact: object}
 * @returns 
 */
export default function EditPerson(props) {

  function generateInviteLink(user) {
    let token = encodeURIComponent(btoa(`t=${user.accountid}:${user.personid}&e=${encodeURIComponent(user.email)}`));
    return `/onboard?tk=${token}`;
  }

  function onModalClose() {
    props.onModalClosed({});
  }

  function onSendInvite() {
    props.onSendInvite(props.person);
  }

  const validateForm = (values) => {
    const errors = {};
 
    if (!values.firstName) {
      errors.firstName = 'First name is required';
    }
    else if (!values.email) {
      errors.email = 'Email is required';
    }      
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  }

  if( props.showModal ) {
    return (
      <Modal>
      <Card>
        <Formik
          initialValues={props.person}
          validate={validateForm}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            // inject the username, if edit
            if(props.person.username)
              values.username = props.person.username;
            props.onModalClosed(values)
          }}
        >
          {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Flex direction="column">
                  <TextField 
                    name="firstName" 
                    id="firstName" 
                    value={props.values.firstName} 
                    onChange={props.handleChange}
                    placeholder="First name" />
                  {props.errors.firstName && <div>{props.errors.firstName}</div>}

                  <TextField 
                    name="lastName" 
                    id="lastName"
                    value={props.values.lastName}  
                    onChange={props.handleChange}
                    placeholder="Last name" />
                  {props.errors.lastName && <div>{props.errors.lastName}</div>}
              
                  { props.values.username &&
                  <div>{props.values.email} </div>}
                  { !props.values.username &&
                  <TextField 
                    name="email" 
                    id="email" 
                    onChange={props.handleChange}
                    value={props.values.email} 
                    placeholder="somebody@somedomain.com" /> }
                  {props.errors.email && <div>{props.errors.email}</div>}

                  <RadioGroupField
                    label="Application Role"
                    name="appRole"
                    defaultValue="Reader"
                    onChange={props.handleChange}
                    value={props.values.appRole}                     
                  >
                    <Radio value="Reader">Reader</Radio>
                    <Radio value="Writer">Writer</Radio>
                  </RadioGroupField>

                  <CheckboxField 
                    name="isAdmin" 
                    onChange={props.handleChange} 
                    checked={props.values.isAdmin} 
                    label="Is Admin"
                    id="isAdmin"/>
                  { props.values.username &&
                  <Flex direction='row'>
                    <span>User Image</span>
                    <input type='file'
                    name='file'
                    id='file'
                    onChange={(e) => props.setFieldValue("file", e.currentTarget.files[0])} />    
                  </Flex>    } 
                  <div>Username: {props.values.personid}</div>  
                  <div/>
                  
                </Flex>
                <Flex direction='row'>
                <Button onClick={onModalClose}>
                  Close
                </Button>
                <Button type="submit">Save</Button>
                {
                    !props.values.status && (
                      <> 
                      {/* <Link href={generateInviteLink(props.values)}>Invite Link</Link> */}
                       <Button onClick={onSendInvite}>
                        Send Invitation
                      </Button>
                      </>
                    )
                  }</Flex>
            </form>
          )}
        </Formik>
      </Card>
      </Modal>
    );
  } else {
    return ( <></>);
  }
}
