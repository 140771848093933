import React from 'react';
import ReactDOM from 'react-dom';
import AppConfig from './config';
import App from './App';
import Signup from './components/signup/Signup';
import Amplify, {Auth} from "aws-amplify";
import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react';
import { Router} from "@gatsbyjs/reach-router";

import reportWebVitals from './reportWebVitals';
import LandingPage from './components/landing/LandingPage';

Amplify.configure({
  "aws_appsync_graphqlEndpoint": AppConfig.graphQlEndpoint,
  "aws_appsync_region": AppConfig.region,
  // "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  // "aws_appsync_apiKey": "da2-2borc46b2bdotparlgqgimzkiq",  
  Auth: {
    // (required)- Amazon Cognito Region
    region: AppConfig.region,

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: AppConfig.userPoolId,

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: AppConfig.appClientId,

    // (optional) - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    clientMetadata: { myCustomKey: 'myCustomValue' },

    //   // (optional) - Hosted UI configuration
    // oauth: {
    //   domain: 'your_cognito_domain',
    //   scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    //   redirectSignIn: 'http://localhost:3000/',
    //   redirectSignOut: 'http://localhost:3000/',
    //   clientId: "1g0nnr4h99a3sd0vfs9",
    //   responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    // }
  },
  API: {
  //   graphql_endpoint: "https://y4gx2cz4hva4tbzmuxxvexgsc4.appsync-api.us-east-2.amazonaws.com/graphql",
  //   region: "us-east-2",
  //   authenticationType: "API_KEY",
  //   apiKey: "da2-cjza4hdc7rah7ju5xba2lhep7i"
  graphql_headers: async () => {
    try {
      const token = (await Auth.currentSession()).idToken.jwtToken;
      return { Authorization: token }
    }
    catch (e) {
      console.error(e);
      return {};
      // Potentially you can retrieve it from local storage
    }
  }  
  }
});

ReactDOM.render(
  <React.StrictMode>
    <AmplifyProvider>
      <Authenticator.Provider>
        <Router>
          <Signup
            path="onboard"
          />
          <App 
            path="admin/*"
          />
          <LandingPage
            path="home"
            default
          />
        </Router>
        
      </Authenticator.Provider>
      </AmplifyProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
