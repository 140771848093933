/* eslint-disable */

export const getPersons = /* GraphQL */ `
query GetPersons {
  getPersons {
    accountid
    personid
    firstName
    email
    lastName
    username
    isAdmin
    appRole
  }

  getAccounts {
    accountid
    name
  }
}
`;

export const getAccountPersons = /* GraphQL */ `
query GetAccountPersons( $accountid: String!) {
  getAccountPersons( accountid: $accountid) {
    accountid
    personid
    firstName
    email
    lastName
    username
    isAdmin
    appRole
  }
}
`;

export const getAccounts = /* GraphQL */ `
query GetAccounts {
  getAccounts {
    accountid
    name
    cloudStorageAccess
    storageAccountRegion
    storageAccountIdentityPoolID
    storageAccountProjectDataBucketName
    storageAccountProjectDataBucketIsAccelerated
    storageAccountSoftwareBucketName
  }
}
`;

export const getAccount = /* GraphQL */ `
query GetAccount($accountid: String!) {
  getAccount(accountid: $accountid ) {
    name
  }
}
`;

// A sample direct lambda query
export const getPosts = /* GraphQL */ `
query GetPosts($field: String!) {
  allPosts(field: $field) {
    author
    id
  }
}
`

export const getSharedAssets = /* GraphQL */ `
query GetSharedAssets {
  getSharedAssets {
    assetid
    version
    versionstring
    filename
    platform
  }
}
`;

