import React, { useState} from 'react';

import AccountList from './AccountList'
import { API } from 'aws-amplify'
import { mutCreateAccount, mutUpdateAccount, mutCreateS3Bucket } from '../../graphql/mutations'
import { getAccounts } from '../../graphql/queries'


export default function AccountListContainer (props) {
    const [accounts, setAccounts] = useState([])

    React.useEffect(() => {
        fetchAccounts()
    },[]);

    /**
     * fetch the contacts from backend
     * 
     */
     async function fetchAccounts() {
        try {
            const accountData = await API.graphql({ query: getAccounts })
            // This is example of direct lambda resolver
            // const postData = await API.graphql({ query: getPosts, variables: {field: "allPosts"} })
            setAccounts(accountData.data.getAccounts)
        } catch (err) { console.log('error fetching accounts', err) }
    }   

    /**
     * 
     * @param {*}  
     */
    async function createAccount(account) {

        const newAcct = await API.graphql({ query: mutCreateAccount, 
            variables: { 
                account: {
                    name: account.name,
                    cloudStorageAccess: parseInt(account.cloudStorageAccess),
                    storageAccountRegion: account.storageAccountRegion,
                    storageAccountIdentityPoolID: account.storageAccountIdentityPoolID,
                    storageAccountProjectDataBucketName: account.storageAccountProjectDataBucketName,
                    storageAccountProjectDataBucketIsAccelerated: account.storageAccountProjectDataBucketIsAccelerated,
                    storageAccountSoftwareBucketName: account.storageAccountSoftwareBucketName                      
                }
            }
        });
        await API.graphql({query: mutCreateS3Bucket, 
            variables: { 
                accountid: newAcct.data.addAccount.accountid
            }
        });
        fetchAccounts();
    }

    /**
     * 
     * @param {*}  
     */
    async function updateAccount(account) {
        await API.graphql({ query: mutUpdateAccount, 
            variables: { 
                accountid: account.accountid,
                account: {
                    name: account.name,
                    cloudStorageAccess: parseInt(account.cloudStorageAccess),
                    storageAccountRegion: account.storageAccountRegion,
                    storageAccountIdentityPoolID: account.storageAccountIdentityPoolID,
                    storageAccountProjectDataBucketName: account.storageAccountProjectDataBucketName,
                    storageAccountProjectDataBucketIsAccelerated: account.storageAccountProjectDataBucketIsAccelerated,
                    storageAccountSoftwareBucketName: account.storageAccountSoftwareBucketName                      
                }
            }
        });
        fetchAccounts();
    }    

    return (

        <AccountList 
            accounts={accounts} 
            onCreateAccount={createAccount} 
            onUpdateAccount={updateAccount}></AccountList>
    );
}