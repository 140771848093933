/* src/App.js */
import React, { useState} from 'react';
import { API } from 'aws-amplify'
import SoftwarePage from './SoftwarePage';
import Header from '../header/Header';
import { getSharedAssets } from '../../graphql/queries'
import {useAuthenticator, Authenticator, View, Image, Flex, Button, Tabs, TabItem} from "@aws-amplify/ui-react";
import CampAccountService from '../../services/svc_account';
import CampAuthService from '../../services/svc_auth';
import CampfireAssetService from '../../services/svc_assets';

import './fonts.css'
import './LandingPage.css'
import '../../App.css';

const softwaresTemplate = {
  ipad: {
    versionstring: '',
    version: 0
  },
  remote: {
    versionstring: '',
    version: 0
  },
  desktop: {
    downloadurl: '',
    versionstring: '',
    version: 0
  },
  scenes: {
    downloadurl: '',
    versionstring: '',
    version: 0
  }
};

const LandingPage = () => {

  const { user } = useAuthenticator();

  const [softwares, setSoftwares] = useState(softwaresTemplate);
  const [companyName, setCompanyName] = useState('');

  const downloadScenes = async () => {
    let creds = await CampAuthService.fetchCredentials(user);
    let s3key = CampfireAssetService.keyNameFromAsset(softwares.scenes);
    CampfireAssetService.downloadAsset(creds,s3key);
  }

  const downloadViewer = async () => {
    let creds = await CampAuthService.fetchCredentials(user);
    let s3key = CampfireAssetService.keyNameFromAsset(softwares.desktop);
    CampfireAssetService.downloadAsset(creds,s3key);
  }

  const loadCompanyInfo = async (currentUser) => {
      const accountInfo = await CampAccountService.getAccount(currentUser.signInUserSession.idToken.payload["custom:accountid"]);
      setCompanyName(accountInfo.name);
  }

  const  fetchPageData = async () => {
    try {

      const assetData = await API.graphql({ query: getSharedAssets });
      for( let asset of assetData.data.getSharedAssets) {
        /*
          assetid: "7480d816-ba25-4313-89a2-9efab1a96cab"
          filename: "Campfire Viewer"
          platform: "iOS"
          version: 8157
          versionstring: "0.8.157"
        */
        if( asset.filename === "Campfire") {
          if( asset.platform === "iOS" && asset.version > softwaresTemplate.ipad.version){
            softwaresTemplate.ipad.versionstring = asset.versionstring;
            softwaresTemplate.ipad.version = asset.version;
          } else if( asset.platform === "Windows" && asset.version > softwaresTemplate.desktop.version) {
            softwaresTemplate.desktop.versionstring = asset.versionstring;
            softwaresTemplate.desktop.assetid = asset.assetid;
            softwaresTemplate.desktop.filename = asset.filename;
            softwaresTemplate.desktop.version = asset.version;
          }

        } else if( asset.filename === "Campfire Remote" && asset.version > softwaresTemplate.remote.version) {
          softwaresTemplate.remote.versionstring = asset.versionstring;
          softwaresTemplate.remote.version = asset.version;
        } 
      }
      setSoftwares({...softwaresTemplate});
    } catch(ex){
      console.log(ex);
    }
  }

  React.useEffect(() => {
    fetchPageData();
    if( user )
      loadCompanyInfo(user);
  }, [user]);

  function toggleVideoPopup(){
    console.log("trigger");
    var el = document.getElementById("video");
    var iframe = el.getElementsByTagName("iframe")[0].contentWindow;
    if (el.classList.contains("hidden")){
      el.classList.remove("hidden");
      iframe.postMessage('{"method":"play"}', '*');
    } else {
      el.classList.add("hidden");
      iframe.postMessage('{"method":"pause"}', '*');
    }
  }

  if( user ) {
    return  (
    <div className='swroot'>

      <Header companyName={companyName}></Header>

      <SoftwarePage version={softwaresTemplate.desktop.versionstring} downloadCallback={downloadViewer}></SoftwarePage>

      <div id="swoosh"></div>
      <div id="swoosh2"></div>
    </div>
    ) ;
  } else {
    return (
      <div>
        <div id='logo'></div>
        <Authenticator className="campfire-app" hideSignUp={true} />
        <div id="swoosh"></div>
        <div id="swoosh2"></div>
    </div>
    );
  }

}

export default LandingPage;
