import React from 'react';
import { Formik } from 'formik';
import { 
  TextField, 
  Card,
  Button,
  Flex } from "@aws-amplify/ui-react";

import Modal from '../modal/Modal';
/**
 * 
 * @param {*} props { showModal: boolean, contact: object}
 * @returns 
 */
export default function EditSoftware(props) {

  // const [selectedFile, setSelectedFile] = useState(null);

  function onModalClose() {
    props.onModalClosed({});
  }

  const validateForm = (values) => {
    const errors = {};
 
    if (!values.filename) {
      errors.filename = 'Filename is required';
    }
    
    if (!values.versionstring) {
      errors.versionstring = 'Version is required';
    }     
    else if (!/^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/i.test(values.versionstring)) {
      errors.versionstring = 'Version must be major.minor.revision with each section less that 3 digits';
    }

    if( !values.platform ) {
      errors.platform = "Platform is required"
    } else if ( 
        values.platform !== 'Windows' &&
        values.platform !== 'Android' &&
        values.platform !== 'iOS') {
      errors.platform = "Platform must be iOS, Android, or Windows"
    }
  
    if( values.status === 'new' &&  !values.file ){
      errors.file = 'Please select a file';
    } 
    //...
  
    return errors;
  }


  if( props.showModal) {
    return (
      <Modal>
      <Card>
        <Formik
          initialValues={props.asset}
          validate={validateForm}
          onSubmit={(values, actions) => {
            console.log("submitted");
            // actions.setSubmitting(false);
            props.onModalClosed(values)
          }}
        >
          {(props) => (
          
            <form onSubmit={props.handleSubmit}>
              <Flex direction="column">

                <TextField 
                  id="filename"
                  value={props.values.filename} 
                  onChange={props.handleChange}
                  name="filename" 
                  placeholder="File Name" />
                {props.errors.filename && <div>{props.errors.filename}</div>}

                <TextField 
                  id="versionstring"
                  value={props.values.versionstring} 
                  onChange={props.handleChange}
                  name="versionstring" 
                  placeholder="Version" />
                {props.errors.versionstring && <div>{props.errors.versionstring}</div>}  

                <TextField 
                  id="platform"
                  value={props.values.platform} 
                  onChange={props.handleChange}
                  name="platform" 
                  placeholder="Platform (Windows | Android | iOS) " />
                {props.errors.platform && <div>{props.errors.platform}</div>}                  

                { props.values.status === 'new' && props.values.platform === 'Windows' && <input type='file'
                  name='file'
                  id='file'
                  
                  onChange={(e) => props.setFieldValue("file", e.currentTarget.files[0])} /> }         

                <Flex direction="row">
                  <Button onClick={onModalClose}>
                    Close
                  </Button>
                  <Button type="submit">Save</Button>
                </Flex>
              </Flex>
            </form>
          )}
        </Formik>
      </Card>
      </Modal>
    );
  } else {
    return ( <></>);
  }
}
