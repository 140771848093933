import { CognitoIdentityClient, 
    GetIdCommand, 
    GetCredentialsForIdentityCommand } from "@aws-sdk/client-cognito-identity"; // ES Modules import
import AppConfig from '../config';
const regionPool = `cognito-idp.${AppConfig.region}.amazonaws.com/${AppConfig.userPoolId}`; 

const CampAuthSvc =  {

    /**
     * 
     * @param {*} currentUser  
     */
    fetchCredentials: async (currentUser) => {
        // const { CognitoIdentityClient, GetIdCommand } = require("@aws-sdk/client-cognito-identity"); // CommonJS import
        const idClient = new CognitoIdentityClient({
            region: AppConfig.region
        });

        const getIdCmdObj = {
            IdentityPoolId: `${AppConfig.region}:${AppConfig.identityPoolId}`,
            Logins: {}
        };

        getIdCmdObj.Logins[regionPool] = currentUser.signInUserSession.idToken.jwtToken;
        const getIdCommand = new GetIdCommand(getIdCmdObj);
        const getIdResponse = await idClient.send(getIdCommand);

        const getCredentialsCmdObject = {
            IdentityId: getIdResponse.IdentityId ,
            Logins: {}
        };

        getCredentialsCmdObject.Logins[regionPool] = currentUser.signInUserSession.idToken.jwtToken;
        const getCredentialsCommand = new GetCredentialsForIdentityCommand(getCredentialsCmdObject);
        return idClient.send(getCredentialsCommand); 
    }

};

export default CampAuthSvc;