import React, { useState} from 'react';
import { 
    useAuthenticator,
    Grid,
    Button,
    View,
    Image,
    Link,
    Flex } from "@aws-amplify/ui-react";
import { Router, Link as ReachLink} from "@gatsbyjs/reach-router";
import PersonListContainer from '../persons/PersonListContainer';
import AccountListContainer from '../accounts/AccountListContainer';
import SoftwareListContainer from '../softwares/SoftwareListContainer';

import { ImExit } from "react-icons/im";

function isSuperAdmin(user) {
    if( user && user.signInUserSession.accessToken.payload["cognito:groups"]) {
        return user.signInUserSession.accessToken.payload["cognito:groups"].includes("SuperAdmins");
    }
    return false;
}

function isAccountAdmin(user) {
    if( user && user.signInUserSession.accessToken.payload["cognito:groups"]) {
        return user.signInUserSession.accessToken.payload["cognito:groups"].includes("Admins");
    }
    return false;
}


export default function AppLayout(props) {
    const [myAccountId, setMyAccountId] = useState('');
    const canAdminAccounts = isSuperAdmin(props.user);
    const canUseSite = canAdminAccounts || isAccountAdmin(props.user);
    const { signOut } = useAuthenticator();

    React.useEffect(() => {
        if( props.user && props.user.signInUserSession.idToken.payload["custom:accountid"]) {
            setMyAccountId( props.user.signInUserSession.idToken.payload["custom:accountid"]);
        } 
    }, [props.user]);

    if( canUseSite && myAccountId) {
        return (
            <Grid
                // columnGap="0.5rem"
                // rowGap="0.5rem"
                // templateColumns="180px 1fr"
                // templateRows="1fr "
            >

                {/*Alex: swapped "Card" for "View components*/}
                {/*https://ui.docs.amplify.aws/react/components/card*/}
                {/*https://ui.docs.amplify.aws/react/components/view*/}

                {/*AT: TODO: Should we make Header into separate component?*/}
                <View className="header">
                    {/*/!*Full Logo*!/*/}
                    {/*<Image width="160px" height="30px" alt="Campfire Logo" src="/logo-full.svg"></Image>*/}
                    <Image className="header-logo" alt="Campfire Logo" src="/logo-c.svg"></Image>

                    {/*/!*Dark Mode*!/*/}
                    {/*<Image width="30px" height="30px" alt="Campfire Logo" src="/logo-c-white.svg"></Image>*/}

                    {/*TODO: This needs to be a company name*/}
                    <span className="companyID">CompanyName</span>

                    <Flex grow='1'></Flex>

                    {/*Alex: TODO: Explore "Tabs" component:*/}
                    {/*https://ui.docs.amplify.aws/react/components/tabs*/}
                    <Flex direction='row'>
                        {canAdminAccounts && <Button variation="link" as={ReachLink} to="/admin/account">Teams</Button>}
                        {canAdminAccounts && <Button variation="link" as={ReachLink} to="/admin/person">All Persons</Button>}
                        {canAdminAccounts && <Button variation="link" as={ReachLink} to="/admin/software">Software</Button>}
                        <Button variation="link" as={ReachLink} to={`/admin/account/${myAccountId}/person/`}>My Persons</Button>
                        {/* <Link as={ReachLink} to={`/account/${myAccountId}/files`}>My Files</Link> */}
                        <Button onClick={signOut}><ImExit w={4} h={4}/></Button>
                    </Flex>

                    <div className="backplate"></div>

                </View>

                <Flex className="pageWrapper"
                      justifyContent="center"
                >
                    <div className="listContainer">
                        <Router >
                            {canAdminAccounts &&
                                <AccountListContainer
                                    path="account"
                                    user={props.user}>
                                </AccountListContainer>
                            }
                            <PersonListContainer
                                path="person"
                                user={props.user}>
                            </PersonListContainer>
                            {canAdminAccounts &&
                                <SoftwareListContainer
                                    path="software"
                                    user={props.user}>
                                </SoftwareListContainer>
                            }
                            <PersonListContainer
                                path="account/:accountid/person"
                                accountid={props.accountid}
                                user={props.user}>
                            </PersonListContainer>
                            {/* <FileListContainer
                                path="account/:accountid/files"
                                accountid={props.accountid}
                                user={props.user}>
                            </FileListContainer> */}
                        </Router>
                    </div>
                </Flex>

                <div id="swoosh"></div>
                <div id="swoosh2"></div>

            </Grid>


        );
    } else {
        return(
            <div>You are not authorized to use this site.</div>
        )
    }
}
