import React from 'react';
import { Formik } from 'formik';
import { 
  TextField, 
  Card,
  Button,
  Flex,
  SelectField,
  CheckboxField } from "@aws-amplify/ui-react";
import Modal from '../modal/Modal';

/**
 * 
 * @param {*} props { showModal: boolean, contact: object}
 * @returns 
 */
export default function EditAccount(props) {

  React.useEffect(() => {
    if(props.showModal) {
      // onOpen();
    }
  }, [props.showModal]);

  function onModalClose() {
    props.onModalClosed({});
    // onClose();
  }

  const validateForm = (values) => {
    const errors = {};
 
    if (!values.name) {
      errors.name = 'Comany name is required';
    } 
    // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.name)) {
    //   errors.name = 'Invalid email address';
    // }
  
    //...
  
    return errors;
  }

  if( props.showModal) {
    return (
    <Modal>
      <Card>
        <Formik
          initialValues={props.account}
          validate={validateForm}
          onSubmit={(values, actions) => {
            console.log("submitted");
            // actions.setSubmitting(false);
            props.onModalClosed(values)
          }}
        >
          {(props) => (
          
            <form onSubmit={props.handleSubmit}>
              <Flex direction="column">

                <TextField 
                  id="name"
                  value={props.values.name} 
                  onChange={props.handleChange}
                  name="name" 
                  placeholder="Account Name" />
                {props.errors.name && <div>{props.errors.name}</div>}


                <SelectField 
                  name="cloudStorageAccess" 
                  id="cloudStorageAccess" 
                  label="Cloud Storage" 
                  value={props.values.cloudStorageAccess}
                  onChange={props.handleChange}>
                  <option value="3">Read and Write</option>
                  <option value="1">Read only</option>
                </SelectField>                  
                {props.errors.cloudStorageAccess && <div>{props.errors.cloudStorageAccess}</div>}                

                <TextField 
                  id="storageAccountIdentityPoolID"
                  value={props.values.storageAccountIdentityPoolID} 
                  onChange={props.handleChange}
                  name="storageAccountIdentityPoolID" 
                  placeholder="Storage Act. ID Pool" />
                {props.errors.storageAccountIdentityPoolID && <div>{props.errors.storageAccountIdentityPoolID}</div>}

                <TextField 
                  id="storageAccountRegion"
                  value={props.values.storageAccountRegion} 
                  onChange={props.handleChange}
                  name="storageAccountRegion" 
                  placeholder="Storage Act. Region" />
                {props.errors.storageAccountRegion && <div>{props.errors.storageAccountRegion}</div>}   

                <TextField 
                  id="storageAccountProjectDataBucketName"
                  value={props.values.storageAccountProjectDataBucketName} 
                  onChange={props.handleChange}
                  name="storageAccountProjectDataBucketName" 
                  placeholder="Storage Act. Assets Bucket Name" />
                {props.errors.storageAccountProjectDataBucketName && <div>{props.errors.storageAccountProjectDataBucketName}</div>}  

                <CheckboxField 
                  name="storageAccountProjectDataBucketIsAccelerated" 
                  onChange={props.handleChange} 
                  checked={props.values.storageAccountProjectDataBucketIsAccelerated} 
                  label="Bucket has Transfer Acceleration"
                  id="storageAccountProjectDataBucketIsAccelerated"/>

                <TextField 
                  id="storageAccountSoftwareBucketName"
                  value={props.values.storageAccountSoftwareBucketName} 
                  onChange={props.handleChange}
                  name="storageAccountSoftwareBucketName" 
                  placeholder="Storage Act. Downloads Bucket Name" />
                {props.errors.storageAccountSoftwareBucketName && <div>{props.errors.storageAccountSoftwareBucketName}</div>}                                                               

                <Flex direction="row">
                  <Button onClick={onModalClose}>
                    Close
                  </Button>
                  <Button type="submit">Save</Button>
                </Flex>
              </Flex>
            </form>
          )}
        </Formik>
      </Card>
      </Modal>  
    );
  } else {
    return ( <></>);
  }
}
