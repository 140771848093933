import React from 'react';
import './Modal.css'

/**
 * 
 * @param {*} props { showModal: boolean, contact: object}
 * @returns 
 */
export default function Modal({children}) {
    return (
        <div className="campfire-modal">
            {children}
        </div>
    );
}