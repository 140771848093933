/* eslint-disable */
export const mutCreatePerson = /* GraphQL */ `
  mutation CreatePerson($person: PersonInput!) {
    addPerson( person: $person) {
      personid
    }
  }
`;

export const mutUpdatePerson = /* GraphQL */ `
  mutation UpdatePerson(
    $personid: String!
    $person: PersonInput!
  ) {
    updatePerson(personid: $personid, person: $person) {
      personid
    }

    cognitoOperation(operation: "syncAdminRole", person: $person) {
      succeeded
      message
    }
  }
`;

export const mutDeletePerson = /* GraphQL */ `
  mutation DeletePerson(
    $personid: String!
    $accountid: String!
  ) {
    deletePerson(personid: $personid, accountid: $accountid) {
      personid
    }
  }
`;

export const mutSendInviteEmail = /* GraphQL */ `
  mutation SendInvitation(
    $person: PersonInput!
  ) {

    cognitoOperation(operation: "sendInvitation", person: $person) {
      succeeded
      message
    }

  }
`;

export const mutUpdateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $accountid: String!,
    $account: AccountInput!
  ) {
    updateAccount( accountid: $accountid, account: $account) {
      name
      cloudStorageAccess
      storageAccountRegion
      storageAccountIdentityPoolID
      storageAccountProjectDataBucketName
      storageAccountProjectDataBucketIsAccelerated
      storageAccountSoftwareBucketName      
    }
  }
`;

export const mutCreateAccount = /* GraphQL */ `
  mutation CreateAccount(
    $account: AccountInput!
  ) {
    addAccount( account: $account) {
      accountid,
      name
    }
  }
`;

export const mutCreateSharedAsset = /* GraphQL */ `
  mutation CreateSharedAsset(
    $sharedAsset: SharedAssetInput!
  ) {
    addSharedAsset( sharedAsset: $sharedAsset) {
      assetid
    }
  }
`;

export const mutUpdateSharedAsset = /* GraphQL */ `
  mutation UpdateSharedAsset(
    $assetid: String!,
    $sharedAsset: SharedAssetInput!
  ) {
    updateSharedAsset( assetid: $assetid, sharedAsset: $sharedAsset) {
      assetid
      version
      filename
      versionstring
      platform
    }
  }
`;

// export const mutCreateCognitoUser = /* GraphQL */ `
//   mutation CreateCognitoUser($person: PersonInput!) {
//   cognitoOperation(operation: "createUser", person: $person) {
//     firstName
//     lastName
//   }
// }`;

export const mutCreateS3Bucket = /* GraphQL */ `
  mutation CreateS3Bucket($accountid: String!) {
  s3Operation(operation: "createBucket", accountid: $accountid) {
    succeeded
    message
  }
}`;