import React, { useState} from 'react';

import SoftwareList from './SoftwareList'
import { API } from 'aws-amplify'
import { mutCreateSharedAsset, mutUpdateSharedAsset} from '../../graphql/mutations'
import { getSharedAssets } from '../../graphql/queries'
import AppConfig from '../../config';

import CampAuthService from '../../services/svc_auth';
import CampfireAssetService from '../../services/svc_assets';

let _credentials = {};

const downloadAsset = async (currentUser, s3objectkey) => {
    _credentials = await CampAuthService.fetchCredentials(currentUser);
    CampfireAssetService.downloadAsset(_credentials,s3objectkey);
}


// const downloadFileBytes = async (currentUser, s3objectkey) => {
//     await fetchCredentials(currentUser);
//     const s3Client = new S3Client({
//         credentials: { 
//             accessKeyId: _credentials.Credentials.AccessKeyId,
//             secretAccessKey: _credentials.Credentials.SecretKey,
//             expiration:_credentials.Credentials.Expiration,
//             sessionToken: _credentials.Credentials.SessionToken
//         },
//         region: AppConfig.region
//     });   

//     const command = new GetObjectCommand({ 
//         Key: `software/${s3objectkey}`,
//         Bucket: AppConfig.softwareBucket
//     });
//     await s3Client.send(command);
// }


export default function SoftwareListContainer (props) {
    const [assets, setAssets] = useState([])

    React.useEffect(() => {
        fetchAssets();
    },[]);

     async function fetchAssets() {
        try {
            const assetData = await API.graphql({ query: getSharedAssets })
            // This is example of direct lambda resolver
            // const postData = await API.graphql({ query: getPosts, variables: {field: "allPosts"} })
            setAssets(assetData.data.getSharedAssets)
        } catch (err) { console.log('error fetching shared assets', err) }
    }
    
    const versionFromVersionString = (versionString) => {
        const versionComponents = versionString.split('.');
        const version = 100000 * parseInt(versionComponents[0]) +
            1000 * parseInt(versionComponents[1]) +
            parseInt(versionComponents[2]);
        return version;
    }

    /**
     * 
     * @param {*}  
     */
    async function createSharedAsset(sharedAsset) {
        // create the file metadata
        let response = await API.graphql({ query: mutCreateSharedAsset, 
            variables: { 
                sharedAsset: {
                    version: versionFromVersionString(sharedAsset.versionstring),
                    filename: sharedAsset.filename,
                    versionstring: sharedAsset.versionstring,
                    platform: sharedAsset.platform
                }
            }
        });

        // upload the file
        if( sharedAsset.platform === 'Windows' && sharedAsset.filename) {
            // {"data":{"addSharedAsset":{"assetid":"020e629c-0344-4419-ad9e-3d09f026c810"}}}
            let s3Key = CampfireAssetService.keyNameFromAsset(sharedAsset);
            _credentials = await CampAuthService.fetchCredentials(props.user);
            await CampfireAssetService.uploadAsset(_credentials, s3Key, sharedAsset.file);
        }

        fetchAssets();
    }

    /**
     * 
     * @param {*}  
     */
    async function updateSharedAsset(sharedAsset) {
        sharedAsset.version = versionFromVersionString(sharedAsset.versionstring);

        // must delete assetid off of the object or GraphQl will fail
        const assetid = sharedAsset.assetid;
        delete sharedAsset.assetid;
        await API.graphql({ query: mutUpdateSharedAsset, 
            variables: { 
                assetid: assetid,
                sharedAsset: sharedAsset
            }
        });
        fetchAssets();
    }    

    return (
        <SoftwareList
            downloadAsset={ (s3key) => downloadAsset(props.user, s3key)} 
            sharedassets={assets} 
            onCreateAsset={createSharedAsset} 
            onUpdateAsset={updateSharedAsset}></SoftwareList>
    );
}