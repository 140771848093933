// AT: This component is written by ChatGPT

import React, { useState } from 'react';
import './VimeoPlayerPopup.scss';

const VimeoPlayerPopup = ({ videoId, thumbnail }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(true);
    };

    const handleClose = (event) => {
        if (event.target === event.currentTarget) {
            setIsOpen(false);
        }
    };

    return (
        <>
            <div className="thumbnail-container">
                <img src={`${thumbnail}`} onClick={handleClick}/>
                <div className="play-button">
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                        <polygon points="70, 55 70, 145 145, 100" fill="#f84536" />
                    </svg>
                </div>
            </div>
            {isOpen && (
                <div className="vimeo-player-popup" onClick={handleClose}>
                    <button onClick={handleClose}>Close</button>
                    <iframe src={`https://player.vimeo.com/video/${videoId}`} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
                </div>
            )}
        </>
    );
};

export default VimeoPlayerPopup;

