import { S3Client, GetObjectCommand, PutObjectCommand  } from "@aws-sdk/client-s3"; // ES Modules import
import AppConfig from '../config';
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

const CampAssetSvc = {
    downloadAsset: async (credentials, s3objectkey) => {
      // Create a presigned URL.
      try {
        const s3Client = new S3Client({
            credentials: { 
                accessKeyId: credentials.Credentials.AccessKeyId,
                secretAccessKey: credentials.Credentials.SecretKey,
                expiration:credentials.Credentials.Expiration,
                sessionToken: credentials.Credentials.SessionToken
            },
            region: AppConfig.region
        });   
    
        const command = new GetObjectCommand({ 
            Key: s3objectkey,
            Bucket: AppConfig.softwareBucket
        });
    
        // Create the presigned URL.
        const signedUrl = await getSignedUrl(s3Client, command, {
          expiresIn: 3600,
        });
    
        window.open(signedUrl,"_blank");
    
      } catch (err) {
        console.log("Error creating presigned URL", err);
      }    
    },

    keyNameFromAsset: (sharedAsset ) => {
      return `software/${sharedAsset.filename} Installer ${sharedAsset.versionstring}.exe`;
    },

    keyNameFromPerson: (person ) => {
      return `account/${person.accountid}/projectdata/UserProfilePics/${person.username}`;
    },    

    uploadAsset: async (credentials, s3objectkey, file) => {
      
      const s3Client = new S3Client({
          credentials: { 
              accessKeyId: credentials.Credentials.AccessKeyId,
              secretAccessKey: credentials.Credentials.SecretKey,
              expiration:credentials.Credentials.Expiration,
              sessionToken: credentials.Credentials.SessionToken
          },
          region: AppConfig.region
      });   

      const command = new PutObjectCommand({ 
          Key: s3objectkey,
          Bucket: AppConfig.softwareBucket,
          Body: file
      });
      await s3Client.send(command);
    } ,
    
    uploadUserImage: async (credentials, s3objectkey, file) => {
      
      const s3Client = new S3Client({
          credentials: { 
              accessKeyId: credentials.Credentials.AccessKeyId,
              secretAccessKey: credentials.Credentials.SecretKey,
              expiration:credentials.Credentials.Expiration,
              sessionToken: credentials.Credentials.SessionToken
          },
          region: AppConfig.region
      });   

      const command = new PutObjectCommand({ 
          Key: s3objectkey,
          Bucket: AppConfig.accountBucket,
          Body: file
      });
      await s3Client.send(command);
    }     

};

export default CampAssetSvc;
