import React from 'react';
import { Table,
    TableHead, 
    TableRow,
    View,
    Button,
    TableBody,
    TableCell,
    Flex } from "@aws-amplify/ui-react";
import { GrEdit, GrFormTrash, GrCheckmark } from "react-icons/gr";

import EditPerson from './EditPerson'
import {TbLogout} from "react-icons/tb";
// import { TableRowiangleDownIcon, TableRowiangleUpIcon } from "@chakra-ui/icons"
// import { useTable, useSortBy } from "react-table"

const personTemplate = {
    username:'',
    firstName:'',
    lastName:'',
    email:'',
    isAdmin: false,
    status: 'new'
}

export default function PersonList(props) {
    const [showModal, setShowModal] = React.useState();
    const [currentPerson, setCurrentPerson] = React.useState();

    function popModal(person) {
        setCurrentPerson(person); 
        setShowModal(true);
    }

    function deletePerson(person) {
        props.onDeletePerson(person);
    }

    function onModalClosed(result) {
        // determine if it is an add or an edit
        if( result.status && result.status === 'new') {
            props.onCreatePerson(result);
        } else if( result.personid) {
            props.onUpdatePerson(result);
        }
        setShowModal(false);
    }


    return (
        <View className="listContainer">
            <Flex p="2">
                {/*<div> {props.accountname} Persons</div>*/}
                {/*<h2> People</h2>*/}
                <Flex grow="1"></Flex>
                { props.accountid &&
                    <Button variation="primary" onClick={(e) => {popModal({...personTemplate, accountid: props.accountid})}  }>Add Person</Button>
                }
            </Flex>

            <Table
                variant="simple"
            >
                {/* <TableCaption>Imperial to meTableRowic conversion factors</TableCaption> */}
                <TableHead>
                    <TableRow>
                        {/* <TableCell>Id</TableCell> */}
                        <TableCell>Registered</TableCell> 
                        { props.accountname == 'All' && <TableCell>Account</TableCell>}
                        <TableCell>First</TableCell>
                        <TableCell>Last</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                    props.persons && props.persons.map((person, index) => (
                        <TableRow className="withBackdrop" key={index}>
                            {/* <TableCell>{person.personid}</TableCell> */}
                             <TableCell>{person.username && <GrCheckmark/>}</TableCell> 
                            { props.accountname == 'All' && <TableCell>{person.accountname}</TableCell> }
                            <TableCell>{person.firstName}</TableCell>
                            <TableCell>{person.lastName}</TableCell>
                            <TableCell>{person.email}</TableCell>
                            <TableCell>
                                <Button onClick={(e) => { popModal(person); }}><GrEdit w={4} h={4}/></Button>
                            </TableCell>

                            <div className="rowBackdrop"></div>
                        </TableRow>
                    ))
                    }
                </TableBody>
            </Table>

            <EditPerson
                person={currentPerson}
                accountid={props.accountid}
                showModal={showModal}
                onSendInvite={props.onSendInvite}
                onModalClosed={onModalClosed} />
        </View>
    );
}