import React, { useState, useEffect } from "react";
import ReleaseNotesContent from "./ReleaseNotesContent";
import Button from "../Input/Button";

import "./ReleaseNotesDialog.scss";

const ReleaseNotesDialog = () => {
    const [showDialog, setShowDialog] = useState(false);


    const toggleDialog = () => {
        setShowDialog(!showDialog);
    };

    // const [version, setVersion] = useState("1.0.0");
    //
    // const updateVersion = (newVersion) => {
    //     setVersion(newVersion);
    // };
    //
    // useEffect(() => {
    //     updateVersion(version);
    // }, []);

    return (
        <div>
            <Button type="link" label="Release Notes" onClick={toggleDialog} />
            {showDialog && (
                <div className="dialog" onClick={toggleDialog}>
                    <div className="close-button-container">
                        <button className="close-button" onClick={toggleDialog}>
                            &#10005;
                        </button>
                    </div>
                    <div className="dialog-inner">
                        <ReleaseNotesContent/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReleaseNotesDialog;
